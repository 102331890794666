/* tslint:disable:jsx-no-multiline-js, variable-name */
import * as React from 'react'
import { HashRouter as Router, RouteComponentProps as RCP } from 'react-router-dom'

import { Actions, store } from './modal_redux'
// import { ActiveModal } from './modal'
import SlientRoute from './slient_route'

// import * as ModalRedux from './modal_redux'

interface Props {
  callback?: (props?: RCP) => void | Promise<void>
}
interface State { }

export default class ModalRouter extends React.Component<Props, State> {
  private _callback: (props: RCP) => Promise<void>

  constructor(props) {
    super(props)
    this._callback = async (_props: RCP) => {
      const route: string = _props.match.params['route']
      if (route.indexOf('modal-') > -1) {
        const pageTag = route.replace(/.*modal\-/, '')
        const [pageType, _pageIndex] = pageTag.split('-')
        const pageIndex = parseInt(_pageIndex, 10)
        store.dispatch(Actions.showPage(pageType, pageIndex))
        // const successFlg = await ActiveModal(pageTag)
        // if (this.props.callback && successFlg) {
        //   this.props.callback(_props)
        // }
      }
    }
  }

  public render() {
    return (
      <Router hashType='noslash'>
        <SlientRoute path='/:route' callback={this._callback} />
      </Router>
    )
  }
}
