import * as React from 'react'

export function score2Str(s: number, deciLen: number = 2): string {
  const res = s.toString().split('.')
  if (!res[1]) {
    res[1] = [...Array(deciLen)].fill(0).join('')
  } else if (res[1].length < deciLen) {
    res[1] += [...Array(deciLen - res[1].length)].fill(0).join('')
  } else if (res[1].length > deciLen) {
    res[1] = res[1].substring(0, deciLen)
  }
  return res.join('.')
}

export function rawTxt(text: string) {
  const lines = text.split(/\r\n|\r|\n/)
  const len = lines.length
  const res = []

  lines.forEach((line, i) => {
    res.push(line)
    if (i + 1 < len) {
      res.push(React.createElement('br'))
    }
  })
  return res
}

export function dummyData() {
  let dataArray = [...Array(8)].fill(0).map(() => 1 + Math.random() * 4)
  if (Math.random() > 0.5) {
    dataArray = null
  }
  const avgScore = dataArray ? dataArray.reduce((p, c) => p + c) / dataArray.length : null

  let dataGauge0 = Math.floor(Math.random() * 100)
  let dataGauge1 = Math.floor(Math.random() * 100)
  if (Math.random() > 0.5) {
    dataGauge0 = null
  } else if (Math.random() > 0.5) {
    dataGauge1 = null
  }

  return { dataArray, avgScore, dataGauge0, dataGauge1 }
}

export function delay(ms: number) {
  return new Promise(res => setTimeout(res, ms))
}

export function removeHash() {
  // historyをいじると、react-routerが不発する
  // history.pushState(null, document.title, window.location.pathname + window.location.search)
  const scr = $(window).scrollTop()
  location.hash = ''
  $(window).scrollTop(scr)
  // window.history.replaceState({ name: 'removeHash' }, null, window.location.pathname + window.location.search)
}

export function clearSearchResults() {
  const $searchForm: JQuery<HTMLFormElement> = $('#modal #search_hosp_modal')
  const $select = $searchForm.find('#q_prefecture_id_eq')
  const $inputs = $searchForm.find('input:not([type="hidden"])')
  const $btn = $searchForm.find('#search_hosp_modal_btn')
  const $results = $('#modal_search_results')
  if ($select.find('option[value=""]').length <= 0) {
    $select.prepend('<option value="">都道府県を選択してください</option>')
  }
  $select.val('')
  $inputs.val('')
  $btn.prop('disabled', true)
  $results.empty()
}

export function updateSummaryModal(type: string) {
  switch (type) {
    case 'eval':
      // $('#add_summary_area .summary_btn').val(type)
      $('#add_summary_area #summary_type').val(type)
      $('#add_summary_area .summary_ttl').text('年収の投稿')
      $('#add_summary_area .summary_lbl-3').text('年収投稿')
      $('#add_summary-3 .summaryNextBtn').attr('data-hash', 'add_summary-5')
      break
    case 'rev':
      // $('#add_summary_area .summary_btn').val(type)
      $('#add_summary_area #summary_type').val(type)
      $('#add_summary_area .summary_ttl').text('口コミの投稿')
      $('#add_summary_area .summary_lbl-3').text('口コミ投稿')
      $('#add_summary-3 .summaryNextBtn').attr('data-hash', 'add_summary-4')
      break
    default:
      // do nothing
  }
}
// window['updateSummaryModal'] = updateSummaryModal

export function toggleReviewFieldBtn($el: JQuery<any>) {
  const $btns = $el.find('.addReviewAreaBtn, .submitReviewBtn')
  let allFilledFlg = true
  let noMoreThemeFlg = false

  $el.find('select:required').each((i, el) => {
    if (!$(el).val()) {
      allFilledFlg = false
    }
  })

  $el.find('select.-reviewTheme').each((i, el) => {
    const $options = $(el).find('option:not(:disabled):not([value=""])')
    if ($options.length <= 1) {
      noMoreThemeFlg = true
    }
  })

  $el.find('.-reviewText').each((i, el) => {
    const textMin = parseInt(window['REVIEW_TEXT_MIN'], 10)
    const textMax = parseInt(window['REVIEW_TEXT_MAX'], 10)

    const $textArea = $(el)
    const textSize = $textArea.val().toString().replace(/\s|　/g, '').length

    // TODO: 一旦、上限はここでチェックしない、デザイン決まったらまたやる
    // if (textSize < textMin || textSize > textMax) {
    //   allFilledFlg = false
    // }

    if (textSize < textMin) {
      allFilledFlg = false
    }
  })

  if (allFilledFlg) {
    $btns.prop('disabled', false)
  } else {
    $btns.prop('disabled', true)
  }
  if (noMoreThemeFlg) {
    $el.find('.addReviewAreaBtn').hide()
  } else {
    $el.find('.addReviewAreaBtn').show()
  }
}

export function togglePointBallons($el: JQuery<any>) {
  const fieldsNum = $el.find('.review_field').length
  const UserGiftFlg = $el.find('#user_gift_flg').val()
  if (UserGiftFlg) {
    toggleBallonsVisibility(true)
    // if (fieldsNum <= window['MAX_GIFT_CODES']) {
    //   toggleBallonsVisibility(true)
    // } else {
    //   toggleBallonsVisibility(false)
    // }
  } else {
    toggleBallonsVisibility(false)
  }
  calcBallonsPoints(fieldsNum)
}

function toggleBallonsVisibility(showFlg: boolean) {
  if (showFlg) {
    $('.addReviewAreaBtn, .submitReviewBtn').addClass('-balloon2')
    $('.addReviewAreaBtn .Btn3__balloon2').removeClass('hide')
    $('.submitReviewBtn .Btn1__balloon2').removeClass('hide')
  } else {
    $('.addReviewAreaBtn, .submitReviewBtn').removeClass('-balloon2')
    $('.addReviewAreaBtn .Btn3__balloon2').addClass('hide')
    $('.submitReviewBtn .Btn1__balloon2').addClass('hide')
  }
}

function calcBallonsPoints(fieldsNum: number) {
  const unitViewPoints = parseInt(window['VIEW_PT_PER_CMT'], 10)
  const unitAmazonPoints = parseInt(window['AMAZON_PT_PER_CMT'], 10)
  const currentVPt = unitViewPoints * fieldsNum
  const nextVPt = unitViewPoints * (fieldsNum + 1)
  const maxAPt = parseInt(window['MAX_GIFT_CODES'], 10) * unitAmazonPoints
  let currentAPt = unitAmazonPoints * fieldsNum
  let nextAPt = unitAmazonPoints * (fieldsNum + 1)
  if (currentAPt > maxAPt) {
    currentAPt = maxAPt
  }
  if (nextAPt > maxAPt) {
    nextAPt = maxAPt
  }
  $('.review_pt.-nextRev').text(nextVPt)
  $('.amazon_pt.-nextRev').text(nextAPt)
  $('.review_pt.-currentRev').text(currentVPt)
  $('.amazon_pt.-currentRev').text(currentAPt)
}
