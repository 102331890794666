/* tslint:disable:jsx-no-multiline-js, variable-name, import-sources-order */
import * as React from 'react'
import * as ReactDOM from 'react-dom'

import { Actions, observeStore } from './modal_redux'

import { ActiveModal, summaryModalRedirector } from './modal'
import ModalRouter from './router'

// import StepsBar from '../../utils/steps/steps_bar'

import {
  clearSearchResults, delay, removeHash,
  toggleReviewFieldBtn, updateSummaryModal,
} from '../../utils/helper'
// import { PageData, pageList, Steps } from './page_list'

/**
 * window.location.href = '#modal-sign_up-1'
 * Or
 * window.location.hash = 'modal-sign_up-1'
 */
document.addEventListener('turbolinks:load', async () => {

  // const unSubStepWatcher = observeStore(s => {
  //   if (s.type === 'update_step') {
  //     return s
  //   }
  // }, (s: Actions.state) => {
  //   const { pageIndex, pageType } = s
  //   const pageData: PageData = pageList[pageType][pageIndex - 1]
  //   if (pageData) {
  //     const { steps } = pageData
  //     const pageRootId = `#${pageType}`
  //     // const pageRootId = pageData.pageId
  //     // renderStepsBar(pageRootId, steps)
  //   }
  // })

  const unSubShowPage = observeStore(s => {
    if (s.type === 'show_page') {
      return s
    }
  }, async (s: Actions.state) => {
    const { pageType, pageIndex } = s
    const res = summaryModalRedirector(pageType, pageIndex)
    const successFlg = await ActiveModal(res.pageType, res.pageIndex)
  })

  await delay(200)

  ReactDOM.render(
    <ModalRouter />,
    document.querySelector('#modalRouter'),
  )
})

// function observer(update) {
//   const seletor = state => state
//   const onChange = state => update(state)
//   return observeStore(seletor, onChange)
// }

// function renderStepsBar(pageId, steps: Steps) {
//   const dom = document.querySelector(`${pageId} .steps-bar-placehoder`)
//   if (!dom.innerHTML) {
//     // ReactDOM.render(
//     //   <StepsBar {...steps} />,
//     //   dom,
//     // )
//   } else {
//     console.log('TODO: use image')
//   }
// }

// $(document).on('turbolinks:load', () => {
$(document).on('opening', '#modal', el => {
  // console.log('Modal is opening')
})

$(document).on('closing', '#modal', el => {
  // console.log('Modal is closed')
  // window.location.hash = ''
  removeHash()
})

$(document).on('change', '[name="user[agreement]"]', ev => {
  const $el = $(ev.currentTarget)
  if ($el.is(':checked')) {
    if ($('#user_email_modal').val() && $('#user_password_modal').val()) {
      $('#user_sign_in_next').prop('disabled', false)
    }
  } else {
    $('#user_sign_in_next').prop('disabled', true)
  }
})

const signUpInputSelectors = '#user_nickname_modal, #user_prefecture_id, #user_job_typ, #user_job_exp'
$(document).on('change', signUpInputSelectors, () => {
  toggleSignUpSubmitBtn()
})

$(document).on('change', '#search_hosp_modal', ev => {
  const $el = $(ev.currentTarget)
  toggleSearchBtn($el)
})

$(document).on('keyup', '#search_hosp_modal input', ev => {
  const $el = $(ev.currentTarget).parents('form')
  toggleSearchBtn($el)
})

$(document).on('change', '[id^=add_summary-] select, [id^=add_summary-] input', ev => {
  const $el = $(ev.currentTarget).closest('[id^=add_summary-]')
  toggleSummaryNextBtn($el)
  toggleReviewFieldBtn($el)
})

$(document).on('keyup input', '[id^=add_summary-] textarea', ev => {
  const $el = $(ev.currentTarget)
  const $area = $el.closest('[id^=add_summary-]')
  // toggleSummaryNextBtn($area)
  toggleReviewFieldBtn($area)
  reviewTextCounter($el)
})

  // 一旦ここ通していない、'.-reduxLink'でハードコーディングしなさい
  // $(document).on('click', '#modal .nextPage', ev => {
  //   const $el = $(ev.currentTarget)
  //   window.location.hash = `modal-${$el.data('hash')}`
  //   // validate(form)
  //   // 修復！最初、store['pageType']が存在しないから
  //   // const store = window.getModalReduxState()
  //   // const indexNext = parseInt(store['pageIndex'], 10) + 1
  //   // window.location.hash = `modal-${store['pageType']}-${indexNext}`
  // })

// 会員登録モーダル
$(document).on('click', '.-register', el => {
  let $site_name = $("input[name='member_register_site_name']").val()
  let $site_qualification_name = $("input[name='member_register_qualification_name']").val()
  let $site_name_title = `${$site_name}転職ラボ`
  let $title = `会員登録 | ${$site_name_title}`
  let $description = `会員登録はこちら。${$site_qualification_name}による転職口コミサイトは${$site_name_title}。`
  let $keywords = `会員,登録,会員登録,ユーザー,${$site_name_title}`
  // validate(form)
  // window.location.hash = 'modal-sign_up-1'
  window.location.hash = 'modal-before_sign_in-1'
  document.title = $title
  document.querySelector('meta[name="description"]').setAttribute("content", $description)
  document.querySelector('meta[name="keywords"]').setAttribute("content", $keywords)
})

$(document).on('click', '.modal_closeBtn', el => {
  let $page_title = $("input[name='page_title']").val()
  let $page_description = $("input[name='page_description']").val()
  let $page_keywords = $("input[name='page_keywords']").val()
  
  document.title = `${$page_title}`
  document.querySelector('meta[name="description"]').setAttribute("content", `${$page_description}`)
  document.querySelector('meta[name="keywords"]').setAttribute("content", `${$page_keywords}`)
})
// 口コミ用ポイント消化モーダル
$(document).on('click', '.-usePoint', el => {
  window.location.hash = 'modal-unlock_hosp-1'
})

// Rank用ポイント消化モーダル
$(document).on('click', '.-unlockRankHosps', el => {
  window.location.hash = 'modal-unlock_rank-1'
})

// 口コミ投稿モーダル
$(document).on('click', '.-newReview, .-newEvaluation', ev => {
  const $el = $(ev.currentTarget)
  let type = $el.val()
  if (!type) {
    if ($el.hasClass('-newReview')) {
      type = 'rev'
    } else if ($el.hasClass('-newEvaluation')) {
      type = 'eval'
    }
  }

  updateSummaryModal(type.toString())

  const clearSearchResultsFlg = $el.data('clear_search_results')
  if (clearSearchResultsFlg) {
    clearSearchResults()
  }

  window.location.hash = 'modal-add_summary-1'
})

// その他一般的なモーダルリンク
$(document).on('click', '.-reduxLink', ev => {
  const $el = $(ev.currentTarget)
  window.location.hash = `modal-${$el.data('hash')}`
})

// 追加したレビューエリアを削除
$(document).on('click', '.-delReviewField', ev => {
  const $btn = $(ev.currentTarget)
  const $field = $btn.closest('.review_field')
  const $area = $btn.closest('[id^=add_summary-]')
  const unitViewPoints = parseInt(window['VIEW_PT_PER_CMT'], 10)
  let fieldsNum = $('.review_field').length
  let currentVPt = unitViewPoints * (fieldsNum - 1)
  let nextVPt = currentVPt + unitViewPoints

  if (confirm('削除しますか？')) {
    $field.slideUp('fast', () => {
      $field.remove()
      toggleReviewFieldBtn($area)
    })

    $('.review_pt.-currentRev').text(currentVPt)
    $('.review_pt.-nextRev').text(nextVPt)
  }
})

// })

function toggleSignUpSubmitBtn() {
  const $nickname = $('#user_nickname_modal')
  const $pref = $('#user_prefecture_id')
  const $jobTyp = $('#user_job_typ')
  const $jobExp = $('#user_job_exp')
  const $recaptcha = $('#sign_up [name="g-recaptcha-response"]')
  const $inputs = [$pref, $nickname, $jobTyp, $recaptcha, $jobExp]
  const $btn = $('#user_sign_in_submit')

  for (const $input of $inputs) {
    if (!$input.val()) {
      $btn.prop('disabled', true)
      return
    }
  }

  $btn.prop('disabled', false)
}

window['toggleSignUpSubmitBtn'] = toggleSignUpSubmitBtn

function toggleSearchBtn($el: JQuery<any>) {
  const $pref = $el.find('#q_prefecture_id_eq')
  const $name = $el.find('#q_hospital_name_or_hospital_name_kana_or_short_name_cont')
  const $btn = $el.find('#search_hosp_modal_btn')

  if ($pref.val() && $name.val()) {
    $btn.prop('disabled', false)
  } else {
    $btn.prop('disabled', true)
  }
}

function toggleSummaryNextBtn($el: JQuery<any>) {
  const $btns = $el.find('.summaryNextBtn, .submitIncomeBtn')
  let allFilledFlg = true

  $el.find('select:required').each((i, el) => {
    if (!$(el).val()) {
      allFilledFlg = false
    }
  })

  $el.find('.ModalWrap__eval, .-recommend_status').each((i, el) => {
    const $radioSet = $(el)
    if ($radioSet.find(':checked').length === 0) {
      allFilledFlg = false
    }
  })

  if (allFilledFlg) {
    $btns.prop('disabled', false)
  } else {
    $btns.prop('disabled', true)
  }
}

function reviewTextCounter($el: JQuery<any>) {
  const $field = $el.closest('.review_field')
  const $counter = $field.find('.-counter')
  const textSize = $el.val().toString().replace(/\s|　/g, '').length
  const textMin = parseInt(window['REVIEW_TEXT_MIN'], 10)
  let sizeRemain = textMin - textSize
  if (sizeRemain < 0) {
    sizeRemain = 0
  }
  $counter.text(sizeRemain)
}
