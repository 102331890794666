export interface Steps {
  labels: string[]
  className?: string
  currentIndex?: number
  padding?: number
}

export interface PageData {
  pageId: string
  steps?: Steps
}

const signInSteps = {
  labels: ['ログイン情報', '会員情報', '登録完了'],
}

export const pageList = {
  before_sign_in: [
    { pageId: '#before_sign_in-1' },
    // { pageId: '#before_sign_in-2' },
    // { pageId: '#before_sign_in-3' },
  ] as PageData[],
  sign_up: [
    { pageId: '#sign_up-1', steps: { ...signInSteps, currentIndex: 1 } },
    { pageId: '#sign_up-2', steps: { ...signInSteps, currentIndex: 2 } },
    { pageId: '#sign_up-3', steps: { ...signInSteps, currentIndex: 3 } },
    { pageId: '#sign_up-4', steps: { ...signInSteps, currentIndex: 4 } },
  ] as PageData[],
  login: [] as PageData[],
  unlock_hosp: [
    { pageId: '#unlock_hosp-1', steps: { labels: ['Unlock'], currentIndex: 1 } },
    { pageId: '#unlock_hosp-2', steps: { labels: ['Unlock Success'], currentIndex: 2 } },
    { pageId: '#unlock_hosp-3', steps: { labels: ['Unlock Error'], currentIndex: 3 } },
    { pageId: '#unlock_hosp-4', steps: { labels: ['Unlock Error'], currentIndex: 3 } },
  ] as PageData[],
  unlock_rank: [
    { pageId: '#unlock_rank-1', steps: { labels: ['Unlock'], currentIndex: 1 } },
    { pageId: '#unlock_rank-2', steps: { labels: ['Unlock Error'], currentIndex: 2 } },
  ] as PageData[],
  add_summary: [
    { pageId: '#add_summary-1', steps: { labels: ['Add Review'], currentIndex: 1 } },
    { pageId: '#add_summary-2', steps: { labels: ['Add Review'], currentIndex: 2 } },
    { pageId: '#add_summary-3', steps: { labels: ['Add Review'], currentIndex: 3 } },
    { pageId: '#add_summary-4', steps: { labels: ['Add Review'], currentIndex: 4 } },
    { pageId: '#add_summary-5', steps: { labels: ['Add Review'], currentIndex: 5 } },
    { pageId: '#add_summary-6', steps: { labels: ['Add Review Success'], currentIndex: 6 } },
    { pageId: '#add_summary-7', steps: { labels: ['Add Review Errored'], currentIndex: 7 } },
  ] as PageData[],
}

export function beforeSignInPageTtl(pathname) {
  const text = [ '無料会員登録で' ]
  switch (true) {
    case /rank|ranking/.test(pathname):
      text.push('働きやすい事務所ランキングが')
      text.push('ご覧いただけます！')
      return text.join('<br class="sp">')
    case /bookmark|follow/.test(pathname):
      text.push('働きやすい事務所ランキングが')
      text.push('フォロー機能が使えます！')
      return text.join('<br class="sp">')
    default:
      text.push('口コミがご覧いただけます！')
      return text.join('<br class="sp">')
  }
}
