/* tslint:disable:jsx-no-multiline-js, variable-name */
import * as React from 'react'
import { Route, RouteComponentProps as RCP, RouteProps } from 'react-router-dom'

interface SlientRouteProps extends RouteProps {
  callback: (props: RCP) => void | Promise<void>
}

function SlientComponentFactory(callback: (props: RCP) => void | Promise<void>) {
  const SlientComponent: React.FC<RCP> = props => {
    (async () => callback({...props}))()
    return <React.Fragment />
  }
  return SlientComponent
}

const SCF = SlientComponentFactory

export default class SlientRoute extends React.Component<SlientRouteProps> {
  constructor(props) {
    super(props)
  }

  public render() {
    return (
      <Route {...this.props} component={SCF(this.props.callback)} />
    )
  }

  // private _callback: (any?) => React.ReactNode
  // public render() {
  //   return (
  //     <Route
  //       path={this.props.path}
  //       exact={this.props.exact}
  //       component={SlientComponent}
  //       render={this._callback} />
  //   )
  // }
}
