/**
 * Actions
 */
export class Actions {
  public static BEFORE_LOGIN = 'before_login'
  public static SIGN_UP = 'sign_up'
  public static LOGIN = 'login'
  public static REVIEW = 'review'
  public static EVALUATION = 'evaluation'
  public static SHOW_PAGE = 'show_page'
  public static UPDATE_STEP = 'update_step'

  public static beforeLogin(pageIndex = 1) {
    return { type: Actions.BEFORE_LOGIN, pageIndex }
  }

  public static signIn(pageIndex = 1) {
    return { type: Actions.SIGN_UP, pageIndex }
  }

  public static login(pageIndex = 1) {
    return { type: Actions.LOGIN, pageIndex }
  }

  public static review(pageIndex = 1) {
    return { type: Actions.REVIEW, pageIndex }
  }

  public static evaluation(pageIndex = 1) {
    return { type: Actions.EVALUATION, pageIndex }
  }

  public static showPage(pageType: string, pageIndex: number) {
    return { type: Actions.SHOW_PAGE, pageType, pageIndex }
  }

  public static updateStep(currentIndex = 1, pageType: string, pageIndex: number) {
    // const pageId = `#${pageType}-${pageIndex}`
    return { type: Actions.UPDATE_STEP, currentIndex, pageType, pageIndex }
  }
}

export declare namespace Actions {
  type action = {
    type: string,
    pageType?: string,
    pageIndex?: number,
    currentIndex?: number,
  }

  type state = {
    type?: string,
    pageType?: string,
    pageIndex?: number,
    currentIndex?: number,
    prev?: {
      pageIndex?: number,
      currentIndex?: number,
    },
  }
}

/**
 * Reducer
 */
const initState = {
  pageIndex: 1,
  currentIndex: 1,
  // pageId: ''
}

const reducer = (state: Actions.state = initState, action: Actions.action) => {
  const { type } = action
  let prev = getPrevData(state, action)

  switch (type) {
    case Actions.BEFORE_LOGIN:
    case Actions.SIGN_UP:
    case Actions.LOGIN:
    case Actions.REVIEW:
    case Actions.EVALUATION:
      prev = { pageIndex: state.pageIndex }
      // console.log(state, prev)
      return { ...state, ...action, prev, type }
    case Actions.UPDATE_STEP:
      return { ...state, ...action, prev, type }
    case Actions.SHOW_PAGE:
      return { ...state, ...action, prev, type }
    default:
      return state
  }
}

function getPrevData(state, action): Actions.state {
  const prev = {}
  Object.keys(action).forEach(key => {
    if (key !== 'type' && key !== 'prev' && state[key] !== undefined) {
      prev[key] = state[key]
    }
  })
  return prev
}

/**
 * Store
 */
import { createStore } from 'redux'
export const store = createStore(reducer)

/**
 * observer
 */
export const observeStore = (selector: (state: any) => any, onChange: (currentState: any) => void) => {
  let currentState

  function handleChange() {
    const nextState = selector(store.getState())
    if (nextState !== undefined && nextState !== currentState) {
      currentState = nextState
      onChange(currentState)
    }
  }

  const unsubscribe = store.subscribe(handleChange)
  handleChange()
  return unsubscribe
}

/**
 * expose redux api to window
 */
window.feedModalRedux = act => {
  store.dispatch(act)
}
window.getModalReduxState = () => {
  return store.getState()
}
window.modalReduxObserver = (callback: (state: Actions.state) => void, seletor = (state: Actions.state) => state) => {
  // const seletor = state => state
  const onChange = state => callback(state)
  return observeStore(seletor, onChange)
}

// console.log(window.feedModalRedux)

/**
 * eg:
 * var removeListener = modalReduxObserver(console.log, s => {
 *   if (s.type === 'update_step') {
 *     return s.currentIndex
 *   }
 * })
 */
