/* tslint:disable:jsx-no-multiline-js, variable-name */
import { Actions, store } from './modal_redux'
import { beforeSignInPageTtl, PageData, pageList } from './page_list'

import { removeHash, updateSummaryModal } from '../../utils/helper'

interface Props {
  labels?: string[]
}
interface State {
  currentIndex: number
}

export const ActiveModal = async (pageType: string, pageIndex: number) => {
  let successFlg = false
  let logged_in = $('#user_logged_in')

  if (!window.location.hash) {
    return successFlg
  }

  const pageData: PageData = pageList[pageType] && pageList[pageType][pageIndex - 1]
  // const pageRootId = pageData.pageId
  const pageRootId = `#${pageType}`
  if (!document.getElementById(pageType)) {
    // window.location.hash = ''
    removeHash()
    return successFlg
  }

  // DEBUG
  // console.log(pageType, pageIndex, pageData)

  if (pageType && pageIndex && pageData) {
    if (pageType === 'before_sign_in') {
      const pathname = window.location.pathname
      const ttl = beforeSignInPageTtl(pathname)
      $('#before_sign_in-1 .ModalWrap__title').html(ttl)
      // console.log('before_sign_in')
    }

    if (pageType === 'add_summary') {
      const summaryType = $('#summary_type').val()
      // 口コミ・年収投稿のTypeをセット
      updateSummaryModal(summaryType.toString())
    }

    if (pageData.steps) {
      const { currentIndex } = pageData.steps
      store.dispatch(Actions.updateStep(currentIndex, pageType, pageIndex))
    }

    await activeDoms(pageRootId, pageData.pageId)
    const modalClosedFlg = await openModal()

    // ベージ途中で開こうとする場合の対策
    if (!logged_in && modalClosedFlg && pageIndex > 1) {
      window.location.hash = `modal-${pageType}-1`
      return successFlg
    }

    successFlg = true
  }
  return successFlg
}

async function openModal() {
  const modal = $('#modal')
  // const page = $(pageId)
  let modalClosedFlg = false

  if (modal['iziModal']('getState') === 'closed') {
    modal['iziModal']('open')
    modalClosedFlg = true
  }
  return modalClosedFlg
}
async function activeDoms(pageRootId, pageId) {
  $('#modal .ModalWrap, #modal .modalFormArea').addClass('hide')
  $(pageRootId).removeClass('hide')
  $(pageId).removeClass('hide')
  // $('#modal .ModalWrap').addClass('hide')
  // $(pageId).removeClass('hide')
  $(pageRootId).scrollTop(0)
}

export function summaryModalRedirector(pageType, pageIndex) {
  if (pageType === 'add_summary') {
    let hash = window.location.hash.replace('#', '')
    const summaryType = $('#summary_type').val()
    // 口コミ・年収投稿のTypeをセット
    updateSummaryModal(summaryType.toString())
    // 口コミ・年収が投稿済みになっている場合の対策
    if (pageIndex > 1 && pageIndex !== 6 && pageIndex !== 7) {
      const evaluated_flg = $('#summary_evaluated_flg').val()
      const income_flg = $('#summary_income_flg').val()
      const completed_flg = $('#summary_completed_flg').val()
      if (summaryType === 'rev') {
        if (income_flg) {
          // 年収が投稿済みの場合、バイパスリンクを隠す
          $('.-bypassToIncome').addClass('hide')
        }
        if (completed_flg) {
          // 全テーマの口コミが投稿済み
          hash = `modal-${pageType}-7`
          // 現在のURLをあたかも`hash`になるよう
          window.history.replaceState({ name: 'add_summary' }, null, '#' + hash)
          return { pageType, pageIndex: 7 }
        } else if (evaluated_flg && pageIndex !== 4 && pageIndex !== 5) {
          // 評価を飛ばす
          hash = `modal-${pageType}-4`
          window.history.replaceState({ name: 'add_summary' }, null, '#' + hash)
          return { pageType, pageIndex: 4 }
        }
      } else if (summaryType === 'eval') {
        if (completed_flg) {
          // 全テーマの口コミが投稿済みの場合、バイパスリンクを隠す
          $('-bypassToReview').addClass('hide')
        }
        if (income_flg) {
          // 年収が投稿済み
          hash = `modal-${pageType}-7`
          window.history.replaceState({ name: 'add_summary' }, null, '#' + hash)
          return { pageType, pageIndex: 7 }
        } else if (evaluated_flg && pageIndex !== 5 && pageIndex !== 4) {
          // 評価を飛ばす
          hash = `modal-${pageType}-5`
          window.history.replaceState({ name: 'add_summary' }, null, '#' + hash)
          return { pageType, pageIndex: 5 }
        }
      }
    }
  }
  return { pageType, pageIndex }
}
